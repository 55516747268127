@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?q4k6ev');
  src:  url('fonts/icomoon.eot?q4k6ev#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?q4k6ev') format('truetype'),
    url('fonts/icomoon.woff?q4k6ev') format('woff'),
    url('fonts/icomoon.svg?q4k6ev#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-keyboard_arrow_right:before {
  content: "\e900";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
