*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
    bigPC: (min-width: 1200px)
    PC: (min-width: 992px)
    iPad: (min-width: 768px)
    bigMobile: (min-width: 576px)
*/
:root {
    /*DARK*/
    /*--tg-theme-bg-color: #1c1c1e; !* Background color: Very dark grey *!*/
    /*--tg-theme-text-color: #ffffff; !* Primary text color: White *!*/
    /*--tg-theme-hint-color: #8e8e93; !* Hint text color: Grey *!*/
    /*--tg-theme-link-color: #5ac8fa; !* Link text color: Light Blue *!*/
    /*--tg-theme-button-color: #007aff; !* Button background color: Blue *!*/
    /*--tg-theme-button-text-color: #ffffff; !* Button text color: White *!*/
    /*--tg-theme-secondary-bg-color: #2c2c2e; !* Secondary background color: Dark Grey *!*/
    /*--tg-theme-header-bg-color: #2c2c2e; !* Header background color: Dark Grey *!*/
    /*--tg-theme-accent-text-color: #5ac8fa; !* Accent text color: Light Blue (for important items) *!*/
    /*--tg-theme-destructive-text-color: #ff375f; !* Destructive action text color: Bright Red (for warnings or delete actions) *!*/
    /*--tg-theme-section-bg-color: #2c2c2e; !* Section background color: Dark Grey (for grouping items) *!*/
    /*--tg-theme-section-header-text-color: #8e8e93; !* Section header text color: Grey *!*/
    /*--tg-theme-subtitle-text-color: #8e8e93; !* Subtitle text color: Grey *!*/

    /*LIGHT*/
    --tg-theme-bg-color: #fff;
    --tg-theme-text-color: #0a0a0a;
    --tg-theme-hint-color: #929292;
    --tg-theme-link-color: #207ae4;
    --tg-theme-button-color: #5bc8fb;
    --tg-theme-button-text-color: #fffeec;
    --tg-theme-secondary-bg-color: #f3f2f9;
    --tg-theme-header-bg-color: #f7f7f7;
    --tg-theme-accent-text-color: #f7f7f7;
    --tg-theme-destructive-text-color: #ff3b30;
    --tg-theme-section-bg-color: #f2f2f7;
    --tg-theme-section-header-text-color: #6d6d72;
    --tg-theme-subtitle-text-color: #8e8e93;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body,
html {
    height: 100%;
    margin: 0;
}

body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1 {
    font-size: calc(1.375rem + 1.5vw);
}

h2 {
    font-size: calc(1.325rem + 0.9vw);
}

h3 {
    font-size: calc(1.3rem + 0.6vw);
}

h4 {
    font-size: calc(1.275rem + 0.3vw);
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul {
    padding-left: 2rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 0.875em;
}

mark {
    padding: 0.1875em;
}

sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

pre,
code,
kbd,
samp {
    font-size: 1em;
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

code {
    font-size: 0.875em;

    word-wrap: break-word;
}

a > code {
    color: inherit;
}

kbd {
    padding: 0.1875rem 0.375rem;
    font-size: 0.875em;

    border-radius: 0.25rem;
}

kbd kbd {
    padding: 0;
    font-size: 1em;
}

figure {
    margin: 0 0 1rem;
}

img {
    object-fit: cover;
}

img,
svg {
    vertical-align: middle;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

label {
    display: inline-block;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
select {
    text-transform: none;
}

[role=button] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

select:disabled {
    opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
    display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer;
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
}

@media (min-width: 1200px) {
    legend {
        font-size: 1.5rem;
    }
}

legend + * {
    clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}

::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
    padding: 0;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

::file-selector-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

iframe {
    border: 0;
}

summary {
    display: list-item;
    cursor: pointer;
}

progress {
    vertical-align: baseline;
}

[hidden] {
    display: none !important;
}
